import React from "react";
import Shimmer from "./Shimmer";
import SkelementElement from "./SkelementElement";

const SkeletonSingleBlog = () => {
    return (
        <div className="skeleton-wrapper">
            <div className="skeleton-blog">
                <div style={{ width: "150px" }}>
                    <SkelementElement type="text" />
                </div>
                <SkelementElement type="title" />
                <SkelementElement type="title" />
                <div style={{ margin: "60px 0" }}>
                    <SkelementElement type="text" />
                    <SkelementElement type="text" />
                    <SkelementElement type="text" />
                    <SkelementElement type="text" />
                    <SkelementElement type="text" />
                    <SkelementElement type="text" />
                    <SkelementElement type="text" />
                </div>
                <div style={{ margin: "60px 0" }}>
                    <SkelementElement type="text" />
                    <SkelementElement type="text" />
                    <SkelementElement type="text" />
                    <SkelementElement type="text" />
                    <SkelementElement type="text" />
                    <SkelementElement type="text" />
                    <SkelementElement type="text" />
                </div>
            </div>
            <Shimmer />
        </div>
    );
};

export default SkeletonSingleBlog;
