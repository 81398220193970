import React, { useEffect } from "react";
import "./home.css";
import Typewriter from "typewriter-effect/dist/core";
import { motion } from "framer-motion";

const Home = () => {
    useEffect(() => {
        const title = document.getElementById("my-title");

        const typewriter = new Typewriter(title, {
            loop: true,
            delay: 75,
        });

        typewriter
            .typeString("MERN Stack Developer")
            .pauseFor(1000)
            .deleteChars(20)
            .typeString("JavaScript Developer")
            .pauseFor(1000)
            .deleteChars(20)
            .typeString("React Developer")
            .pauseFor(1000)
            .deleteChars(15)
            .typeString("Content Writer")
            .pauseFor(1000)
            .start();
    }, []);
    return (
        <motion.section layout className="home primary-bg primary-color">
            <div className="page-container">
                <div className="home-content">
                    <div>
                        <h1 className="home-name">Maruf Marzuq</h1>
                        <div className="home-title">
                            <div id="my-title"></div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.section>
    );
};

export default Home;
