import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./singleBlog.css";

const SingleBlog = ({ blog }) => {
    const { imgURL, title, _id } = blog;
    return (
        <Link to={`/blog/${_id}`}>
            <div className="blog-card">
                <div className="blog-img-container">
                    <LazyLoadImage src={imgURL} effect="blur" />
                </div>
                <div className="blog-title-wrapper">
                    <h3>{title}</h3>
                </div>
            </div>
        </Link>
    );
};

export default SingleBlog;
