import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./blogDetail.css";
import SkeletonSingleBlog from "../../../skeletons/SkeletonSingleBlog";

const BlogDetail = () => {
  const { blog_id } = useParams("blog_id");
  const [blog, setBlog] = useState("");
  useEffect(() => {
    setTimeout(() => {
      fetch("./../blog.json")
        .then((res) => res.json())
        .then((data) => {
          setBlog(data.find((blog) => blog._id === blog_id));
        });
    }, [500]);
  }, []);
  const { title, time, imgURL, description, category } = blog;
  return (
    <section className="work primary-bg primary-color">
      <div className="page-container">
        <div className="bp-header">
          <LazyLoadImage effect="blur" src={imgURL} alt="" />
        </div>
        <div className="bp-body">
          {blog && (
            <>
              <h3>{category}</h3>
              <h2>{title}</h2>
              {description && description.map((para) => <p>{para}</p>)}
            </>
          )}
          {!blog && <SkeletonSingleBlog />}
          <div className="post-footer">
            <div className="pf-left">
              <p>
                <i className="far fa-clock"></i> {time}
              </p>
              <p>
                <i className="fas fa-user"></i> Maruf Marzuq
              </p>
            </div>
            <div className="pf-right">
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div className="post-keywords">
            <a href="#">animate</a>
            <a href="#">bar</a>
            <a href="#">design</a>
            <a href="#">progress</a>
            <a href="#">ui</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetail;
