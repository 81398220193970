import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./workDetail.css";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import { HiUser } from "react-icons/hi";
import { AiFillGithub } from "react-icons/ai";
import { GiClick } from "react-icons/gi";
import { AiFillCalendar } from "react-icons/ai";
import { BiRightArrow } from "react-icons/bi";
import SkeletonSingleWork from "../../../skeletons/SkeletonSingleWork";

const WorkDetail = () => {
  const { id } = useParams("id");
  const [work, setWork] = useState({});
  const [sliderIndex, setSliderIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      fetch("./../work.json")
        .then((res) => res.json())
        .then((data) => {
          setWork(data.find((work) => work._id === id));
          setLoading(false);
        });
    }, [500]);
  }, []);
  const {
    title,
    imgURL,
    serverLink,
    clientLink,
    liveLink,
    description,
    date,
    tech,
    slider,
    admin_panel,
    client_panel,
    details_img,
    is_crm,
  } = work;
  const totalSlider = slider?.length || 1;
  const handleSlider = (type) => {
    if (type === "left") {
      setSliderIndex(sliderIndex - 1);
    } else {
      setSliderIndex(sliderIndex + 1);
    }
  };

  return (
    <section className="work primary-bg primary-color">
      <div className="page-container">
        <div>
          <Link to="/work">
            <span className="lnr lnr-arrow-left"></span>
          </Link>
        </div>
        {!loading ? (
          <div>
            <div className="sw-image">
              {slider ? (
                <img src={slider[sliderIndex]} alt="" />
              ) : (
                <LazyLoadImage effect="blur" src={imgURL} alt="" />
              )}
            </div>
            <div className="sw-heading">
              <h2>{title}</h2>
              <div className="testi-slider-btns">
                <button
                  className={sliderIndex === 0 ? "disabled" : ""}
                  onClick={() => handleSlider("left")}
                >
                  <AiOutlineLeft />
                </button>
                <button
                  className={sliderIndex === totalSlider - 1 ? "disabled" : ""}
                  onClick={() => handleSlider("right")}
                >
                  <AiOutlineRight />
                </button>
              </div>
            </div>
            <div className={`project-desc ${is_crm}`}>
              <div>
                <p className="work-overview">
                  {description && description.overview}
                </p>

                <table className="work-desc-table">
                  {description &&
                    description.list.map((listItem, i) => (
                      <tr key={i}>
                        <td>
                          <BiRightArrow />
                        </td>
                        <td>{listItem}</td>
                      </tr>
                    ))}
                </table>
              </div>
              <div>
                <div className="project-links-container">
                  {admin_panel && (
                    <a target="_blank" href={admin_panel} rel="noreferrer">
                      <GiClick /> <span>Admin Panel</span>
                    </a>
                  )}
                  {admin_panel && (
                    <ul className="credential-list">
                      <li>
                        Email: <span>admin@mail.com</span> or{" "}
                        <span>staff@mail.com</span>
                      </li>
                      <li>
                        Password: <span>password</span>
                      </li>
                    </ul>
                  )}
                  {client_panel && (
                    <a target="_blank" href={client_panel} rel="noreferrer">
                      <GiClick /> <span>Client Panel</span>
                    </a>
                  )}
                  {client_panel && (
                    <ul className="credential-list">
                      <li>
                        Email: <span>client@mail.com</span>
                      </li>
                      <li>
                        Password: <span>password</span>
                      </li>
                    </ul>
                  )}
                  {liveLink && (
                    <a target="_blank" href={liveLink} rel="noreferrer">
                      <GiClick /> <span>Live preview</span>
                    </a>
                  )}
                  {clientLink && (
                    <a target="_blank" href={clientLink} rel="noreferrer">
                      <AiFillGithub /> <span>Client side code</span>
                    </a>
                  )}

                  {serverLink && (
                    <a target="_blank" href={serverLink} rel="noreferrer">
                      <AiFillGithub /> <span>Server side code</span>
                    </a>
                  )}

                  <h4>
                    <AiFillCalendar /> <span>{date}</span>
                  </h4>
                  <h4>
                    <HiUser /> <span>Maruf Marzuq</span>
                  </h4>
                  <p>Technology</p>
                  <div className="tech-container">
                    {tech && tech.map((t) => <span>{t}</span>)}
                  </div>
                </div>
              </div>
            </div>
            {details_img && (
              <img
                src={details_img}
                alt=""
                style={{ width: "100%", paddingBottom: "40px" }}
              />
            )}
          </div>
        ) : (
          <SkeletonSingleWork></SkeletonSingleWork>
        )}
      </div>
    </section>
  );
};

export default WorkDetail;
