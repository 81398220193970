import React, { useState, useEffect } from "react";
import SkeletonWork from "../../skeletons/SkeletonWork";
import SingleWork from "./singleWork/SingleWork";
import "./work.css";
import { motion } from "framer-motion";

const Work = () => {
  const [works, setWorks] = useState(null);
  const [filteredWorks, setFilteredWorks] = useState(null);
  const [tabName, setTabName] = useState("All");
  useEffect(() => {
    setTimeout(() => {
      fetch("./work.json")
        .then((res) => res.json())
        .then((data) => {
          setWorks(data);
          setFilteredWorks(data);
        });
    }, [500]);
  }, []);

  useEffect(() => {
    if (tabName === "All") {
      setFilteredWorks(works);
    } else {
      const filteredArray = works.filter((work) => work.tech.includes(tabName));
      setFilteredWorks(filteredArray);
    }
  }, [tabName]);

  return (
    <section className="work primary-bg primary-color">
      <div className="page-container">
        <h2 className="page-title">Works</h2>
        <div className="work-filter">
          <button
            onClick={() => setTabName("All")}
            className={tabName === "All" ? "active" : ""}
          >
            All
          </button>
          <button
            onClick={() => setTabName("React")}
            className={tabName === "React" ? "active" : ""}
          >
            React
          </button>
          <button
            onClick={() => setTabName("NodeJs")}
            className={tabName === "NodeJs" ? "active" : ""}
          >
            NodeJs
          </button>
          <button
            onClick={() => setTabName("MongoDB")}
            className={tabName === "MongoDB" ? "active" : ""}
          >
            MongoDB
          </button>
          <button
            onClick={() => setTabName("JavaScript")}
            className={tabName === "JavaScript" ? "active" : ""}
          >
            JavaScript
          </button>
          <button
            onClick={() => setTabName("HTML")}
            className={tabName === "HTML" ? "active" : ""}
          >
            HTML/CSS
          </button>
        </div>
        <motion.div layout className="work-container">
          {filteredWorks &&
            filteredWorks.map((work) => (
              <SingleWork work={work} key={work._id} />
            ))}
          {!filteredWorks &&
            [0, 1, 2, 3, 4, 5].map((n) => <SkeletonWork key={n} />)}
        </motion.div>
      </div>
    </section>
  );
};

export default Work;
