import React from "react";
import { Link } from "react-router-dom";
import "./singleWork.css";
import { motion } from "framer-motion";

const SingleWork = ({ work }) => {
  const { imgURL, title, category, _id } = work;
  return (
    <Link to={`/work/${_id}`}>
      <motion.div layout className="work-card">
        <div className="card-browser">
          <span className="circle-1"></span>
          <span className="circle-2"></span>
          <span className="circle-3"></span>
        </div>
        <div
          className="work-img-wrapper"
          style={{ backgroundImage: `url(${imgURL})` }}
        >
          <span className="project-category">{category}</span>
        </div>
        <h3 className="work-name">{title}</h3>
      </motion.div>
    </Link>
  );
};

export default SingleWork;
