import React from "react";
import Shimmer from "./Shimmer";
import SkelementElement from "./SkelementElement";

const SkeletonBlog = ({ theme }) => {
    return (
        <div className="skeleton-wrapper">
            <div className="skeleton-blog">
                <SkelementElement type="square" />
                <SkelementElement type="text" />
                <SkelementElement type="text" />
            </div>
            <Shimmer />
        </div>
    );
};

export default SkeletonBlog;
