import React from "react";
import Shimmer from "./Shimmer";
import SkelementElement from "./SkelementElement";

const SkeletonWork = ({ theme }) => {
    return (
        <div className="skeleton-wrapper">
            <div className="skeleton-work">
                <SkelementElement type="square" />
                <SkelementElement type="title" />
            </div>
            <Shimmer />
        </div>
    );
};

export default SkeletonWork;
