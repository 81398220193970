import React from "react";
import Shimmer from "./Shimmer";
import SkelementElement from "./SkelementElement";

const SkeletonSingleWork = () => {
    return (
        <div className="skeleton-wrapper">
            <div className="skeleton-single-work">
                <SkelementElement type="square" />

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "300px" }}>
                        <SkelementElement type="title" />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "70px" }}>
                        <div style={{ width: "30px" }}>
                            <SkelementElement type="title" />
                        </div>
                        <div style={{ width: "30px" }}>
                            <SkelementElement type="title" />
                        </div>
                    </div>
                </div>
            </div>
            <Shimmer />
        </div>
    );
};

export default SkeletonSingleWork;
