import "./App.css";
import { Routes, Route } from "react-router-dom";
import About from "./pages/about/About";
import Home from "./pages/home/Home";
import SideMenu from "./pages/shared/sideMenu/SideMenu";
import Profile from "./pages/shared/profile/Profile";
import Contact from "./pages/contact/Contact";
import Blog from "./pages/blog/Blog";
import Work from "./pages/work/Work";
import WorkDetail from "./pages/work/workDetail/WorkDetail";
import BlogDetail from "./pages/blog/blogDetail/BlogDetail";
import { motion } from "framer-motion";

function App() {
    return (
        <div className="App">
            <main>
                <div className="main-container">
                    <SideMenu />
                    <Profile />
                    <motion.div layout className="scrollable-container">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/work" element={<Work />} />
                            <Route path="/work/:id" element={<WorkDetail />} />
                            <Route path="/blog" element={<Blog />} />
                            <Route path="/blog/:blog_id" element={<BlogDetail />} />
                            <Route path="/contact" element={<Contact />} />
                        </Routes>
                    </motion.div>
                </div>
            </main>
        </div>
    );
}

export default App;
