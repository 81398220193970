import React, { useState } from "react";
import "./about.css";
import img from "../../assets/images/unknown.jpg";
import { MdOutlineDevices } from "react-icons/md";
import { HiOutlineCode } from "react-icons/hi";
import { BsWordpress } from "react-icons/bs";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import { motion } from "framer-motion";
const About = () => {
  const skills = [
    {
      title: "JavaScript",
      year: 2,
      project: 14,
    },
    {
      title: "React Js",
      year: 1,
      project: 12,
    },
    {
      title: "Gatsby",
      year: 1,
      project: 2,
    },
    {
      title: "Node Js",
      year: 1,
      project: 10,
    },
    {
      title: "MongoDB",
      year: 1,
      project: 9,
    },
    {
      title: "PostgreSQL",
      year: 1,
      project: 2,
    },

    {
      title: "Material UI",
      year: 1,
      project: 3,
    },
    {
      title: "HTML / CSS",
      year: 3,
      project: 16,
    },
  ];
  const [sliderIndex, setSliderIndex] = useState(0);
  const handleArrow = (arrow) => {
    if (arrow === "left") {
      setSliderIndex(sliderIndex - 1);
    } else {
      setSliderIndex(sliderIndex + 1);
    }
  };
  return (
    <motion.section layout className="about primary-bg primary-color">
      <div className="page-container">
        <h2 className="page-title">
          About <span className="colorized">Me</span>
        </h2>
        <div className="about-container">
          <div className="a-text">
            <p>
              Hi, I'm Maruf Marzuq - a full stack web developer from Bangladesh.
              I’ve a deep interest in JavaScript, sticking up for learning and
              developing myself every day. I always focus on learning new
              technology.
            </p>
            <p>
              I believe web development is the field where I can show my
              creativity by best use of my merit and skills. When I'm not
              coding, you can find me geeking about photography, trying new
              food, and exploring different ways of creating content that can
              help people pivot their tech careers.
            </p>
            <p>
              I'm happiest when I'm creating, learning, exploring and thinking
              about how to make things better.Currently learning data structures
              and algorithms and solving problems at HackerRank. I'm available
              for any freelance work, feel free to reach out and say hello!
            </p>
          </div>
          <div className="a-address">
            <p>
              <span className="colorized">Age</span> 23
            </p>
            <p>
              <span className="colorized">Residence</span> Bangladesh
            </p>
            <p>
              <span className="colorized">Address</span> Banani, Dhaka
            </p>
            <p>
              <span className="colorized">E-mail</span> marufmarzuq18@gmail.com
            </p>
            <p>
              <span className="colorized">Phone</span> +880 1760265926
            </p>
          </div>
        </div>
        <div className="edu-skill">
          <div>
            <div className="education">
              <h3 className="sub-title">Education</h3>
              <div className="ee-box">
                <div className="ee-box-left">
                  <h4>2024</h4>
                  <h5>Govt. Titumir College</h5>
                </div>

                <div className="ee-box-right">
                  <h3>BS Honours in Mathematics</h3>
                  <p>
                    I'm studying mathematics at Govt. Titumir college, Dhaka. I
                    love mathematics, data structures and algorithms.
                  </p>
                </div>
              </div>

              <div className="ee-box">
                <div className="ee-box-left">
                  <h4>2021</h4>
                  <h5>Programming Hero</h5>
                </div>

                <div className="ee-box-right">
                  <h3>Complete Web Development </h3>
                  <p>
                    From there I learned advance JavaScript, React, MongoDB and
                    Node Js. The course was mainly front end focus. And the
                    course was one of my turning point for my careers.
                  </p>
                </div>
              </div>

              <div className="ee-box">
                <div className="ee-box-left">
                  <h4>2020</h4>
                  <h5>Udemy</h5>
                </div>

                <div className="ee-box-right">
                  <h3>The Complete JavaScript Course | From Zero to Expert!</h3>
                  <p>
                    From there I learned basic to advance JavaScript and use of
                    JavaScript. Learned JavaScript in depth from this course.
                  </p>
                </div>
              </div>
            </div>
            <div className="education">
              <h3 className="sub-title">Experience</h3>
              <div className="ee-box">
                <div className="ee-box-left">
                  <h4>2022</h4>
                  <h5>Appstone Pvt. Ltd.</h5>
                </div>

                <div className="ee-box-right">
                  <h3>Associate Software Developer</h3>
                  <p>
                    - Worked on tech solutions for medical needs
                    <br />- Redesigned and developed several web applications
                    using MUI, Redux, React.js
                  </p>
                  <p>
                    <strong>Products:</strong> DrSignet Website, Admin Panel,
                    Signet Pharma, Signet Laboratory
                  </p>
                  <p>
                    <strong>Skills:</strong> MUI · Redux · React.js
                  </p>
                </div>
              </div>
              <div className="ee-box">
                <div className="ee-box-left">
                  <h4>2021</h4>
                  <h5>Aqgromalin Farmtech</h5>
                </div>

                <div className="ee-box-right">
                  <h3>Full stack web developer</h3>
                  <p>
                    - Worked on tech-driven solutions for farm holders. Mostly,
                    I made user interface with Javascript and react for
                    different kind of web application
                    <br />
                    - Developed a couple of web apps using React.js library.
                    <br />- Had experience with PostgreSQL
                  </p>
                  <p>
                    <strong>Products:</strong> Products: AQAI OMS, AQAI FMS
                    (Farm Management System)
                  </p>
                  <p>
                    <strong>Skills:</strong> MUI · Redux · React.js
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="skill">
            <h3 className="sub-title">Coding Skills</h3>
            <div className="skill-boxes">
              {skills.map((skill) => (
                <div className="single-skill-box">
                  <div className="skill-box-left">
                    <div className="skill-title">{skill.title}</div>
                    <p>
                      {skill.project} projects | <span>{skill.year} years</span>
                    </p>
                  </div>
                  <div className="skill-level">
                    {Array(skill.project)
                      .fill(0)
                      .map((n) => (
                        <div className="project-level"></div>
                      ))}
                    {Array(skill.year)
                      .fill(0)
                      .map((n) => (
                        <div className="year-level"></div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="what-ido ">
          <h3 className="sub-title">
            What <span>I Do</span>
          </h3>
          <div className="wi-boxes">
            <div className="wi-box">
              <MdOutlineDevices />

              <h4>Front-end Development</h4>
              <p>
                I like to code things from scratch, and enjoy bringing ideas to
                life in the browser.
              </p>
            </div>
            <div className="wi-box">
              <HiOutlineCode />
              <h4>Back-end Development</h4>
              <p>
                I genuinely care about client, and love to give my best to make
                the job perfect.
              </p>
            </div>
            <div className="wi-box">
              <BsWordpress />
              <h4>Wordpress Theme Customization</h4>
              <p>
                I value simple content structure, clean design patterns, and
                thoughtful interactions with this powerful CMS.
              </p>
            </div>
          </div>
        </div>
        <div className="testimonial">
          <div className="testimonial-header">
            <h3 className="sub-title">Testimonials</h3>
            <div className="testi-slider-btns">
              <button
                onClick={() => handleArrow("left")}
                className={sliderIndex === 0 ? "disabled" : ""}
              >
                <AiOutlineLeft />
              </button>
              <button
                onClick={() => handleArrow("right")}
                className={sliderIndex === 2 ? "disabled" : ""}
              >
                <AiOutlineRight />
              </button>
            </div>
          </div>
          <div className="testi-slider-container">
            <div
              className="testi-boxes"
              style={{
                transform: `translateX(${sliderIndex * -100}%)`,
              }}
            >
              <div className="testi-box">
                <img src={img} alt="" />
                <p>
                  Vivamus at molestie dui, eu ornare orci. Curabitur vel egestas
                  dolor. Nulla condimentum nunc sit amet urna tempus finibus.
                  Duis mollis leo id ligula pellentesque, at vehicula dui
                  ultrices.
                </p>
                <h4>Sumaiya Nusrat</h4>
                <h5>Ishwardi Express</h5>
              </div>
              <div className="testi-box">
                <img src={img} alt="" />
                <p>
                  Vivamus at molestie dui, eu ornare orci. Curabitur vel egestas
                  dolor. Nulla condimentum nunc sit amet urna tempus finibus.
                  Duis mollis leo id ligula pellentesque, at vehicula dui
                  ultrices.
                </p>
                <h4>Mahmudul</h4>
                <h5>Pabna Express</h5>
              </div>
              <div className="testi-box">
                <img src={img} alt="" />
                <p>
                  Vivamus at molestie dui, eu ornare orci. Curabitur vel egestas
                  dolor. Nulla condimentum nunc sit amet urna tempus finibus.
                  Duis mollis leo id ligula pellentesque, at vehicula dui
                  ultrices.
                </p>
                <h4>Robin Fahad</h4>
                <h5>Ishwardi Express</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default About;
