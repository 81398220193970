import React from "react";
import "./skeleton.css";

const SkelementElement = ({ type }) => {
    const classes = `skeleton ${type}`;
    return (
        <div>
            <div className={classes}></div>
        </div>
    );
};

export default SkelementElement;
