import React, { useState, useEffect } from "react";
import SkeletonBlog from "../../skeletons/SkeletonBlog";
import "./blog.css";
import SingleBlog from "./singleBlog/SingleBlog";
const Blog = () => {
  const [blogs, setBlogs] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      fetch("./blog.json")
        .then((res) => res.json())
        .then((data) => {
          setBlogs(data);
        });
    }, [500]);
  }, []);
  return (
    <section className="work primary-bg primary-color">
      <div className="page-container">
        <h2 className="page-title">Blog</h2>
        <div className="blog-container">
          {blogs &&
            blogs.map((blog) => <SingleBlog blog={blog} key={blog._id} />)}
          {!blogs && [0, 1].map((n) => <SkeletonBlog key={n} />)}
        </div>
      </div>
    </section>
  );
};

export default Blog;
